//
// Case 5.
// waiting for 3tiao to Hu
// To test for An Gang

export const closedHand_case = [
  // 4tong x  3
  {
    defaultParams: {
      suit: "tong",
    },
    id: 501,
    justDrawn: false,
    name: "4tong",
    params: {
      suit: "tong",
    },
    value: 4,
    visualCardIndex: 21,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 502,
    justDrawn: false,
    name: "4tong",
    params: {
      suit: "tong",
    },
    value: 4,
    visualCardIndex: 21,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 503,
    justDrawn: false,
    name: "4筒",
    params: {
      suit: "tong",
    },
    value: 4,
    visualCardIndex: 21,
  },
  // tong 5, 6, 7
  {
    defaultParams: {
      suit: "tong",
    },
    id: 504,
    justDrawn: false,
    name: "5筒",
    params: {
      suit: "tong",
    },
    value: 5,
    visualCardIndex: 22,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 505,
    justDrawn: false,
    name: "6筒",
    params: {
      suit: "tong",
    },
    value: 6,
    visualCardIndex: 23,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 506,
    justDrawn: false,
    name: "7筒",
    params: {
      suit: "tong",
    },
    value: 7,
    visualCardIndex: 24,
  },
  // 9tiao x 2
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 507,
    justDrawn: false,
    name: "9tiao",
    params: {
      suit: "tiao",
    },
    value: 9,
    visualCardIndex: 17,
  },
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 508,
    justDrawn: false,
    name: "9条",
    params: {
      suit: "tiao",
    },
    value: 9,
    visualCardIndex: 17,
  },
  // tiao 4,5
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 509,
    justDrawn: false,
    name: "4条",
    params: {
      suit: "tiao",
    },
    value: 4,
    visualCardIndex: 12,
  },
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 510,
    justDrawn: false,
    name: "5条",
    params: {
      suit: "tiao",
    },
    value: 5,
    visualCardIndex: 13,
  },
  // 2tiao x 3
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 511,
    justDrawn: false,
    name: "2条",
    params: {
      suit: "tiao",
    },
    value: 2,
    visualCardIndex: 10,
  },
  //
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 512,
    justDrawn: false,
    name: "2条",
    params: {
      suit: "tiao",
    },
    value: 2,
    visualCardIndex: 10,
  },
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 513,
    justDrawn: false,
    name: "2条",
    params: {
      suit: "tiao",
    },
    value: 2,
    visualCardIndex: 10,
  },
]
