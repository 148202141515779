import React, { useMemo, useEffect, useState } from "react"
import { Decimal } from "decimal.js"
// import { Decimal128 } from "mongodb"
//import components
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Alchemy, Network } from "alchemy-sdk"

import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Progress,
  Form,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap"
import axios from "axios"

Decimal.config({ rounding: Decimal.ROUND_DOWN })

function RevenueSplitPage() {
  const idToken = localStorage.getItem("idToken")
  const [user, setUser] = useState()
  const [commWalletBalance, setCommWalletBalance] = useState()
  const [includeOpsFund, setIncludeOpsFund] = useState(false)
  const [totalBalance, setTotalBalance] = useState(new Decimal(0))
  const [balances, setBalances] = useState({
    // Ops: {
    //   userIndex: "",
    //   balance: new Decimal(0),
    //   address: ""
    // }
    Tom: {
      uid: "azob5p0u52YgOXPJjypPa4vJHpx1",
      balance: new Decimal(0),
      address: "0x9E7518a2c0c9ee07AB7679289C76B2C5F9B3fd56",
      split: 0.45,
    },
    East: {
      uid: "Wp1m7VpfRiPJSRmc7NDmXxSNWvI3",
      balance: new Decimal(0),
      address: "0x9862c46eF6fa82a98D521D977f1A8290BCFa0271",
      split: 0.33,
    },
    Jong: {
      uid: "VBbZlNV4qrS809MwqK814Y0q0c82",
      balance: new Decimal(0),
      address: "0x4e483F646B814fD3Df670D84a6C7951D7163529c",
      split: 0.22,
    },
  })

  const getUserBalance = async userIndex => {
    console.log("calling getUserBalance()...")
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/getUserBalance`,
        { userIndex },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      ) // Adjust the domain as necessary
      .then(response => {
        const user = response.data
        setUser(user)
        console.log(user)
        setCommWalletBalance(new Decimal(user.availableUsdBalance))
        // setIsLoading(false)
      })
      .catch(error => {
        console.error("There was an error:", error)
        // setError(error.message)
        // setIsLoading(false)
      })
  }

  useEffect(() => {
    getUserBalance(27)
  }, [])

  const calculateBalances = () => {
    const totalBalance = new Decimal(commWalletBalance)
    let remainingBalance

    if (includeOpsFund) {
      const opsBalance = totalBalance.mul(0.2) // 20% to Account A
      remainingBalance = totalBalance.sub(opsBalance)
      setBalances({
        ...balances,
        Ops: {
          uid: "Ops",
          balance: opsBalance,
          address: "0x7bE621Ec3aBbC6875233C6375418e29A99Fc621a",
          split: 0.2,
        },
      })
    } else {
      const newBalances = { ...balances }
      delete newBalances.Ops
      setBalances(newBalances)
      remainingBalance = totalBalance
    }

    // Update balances for Tom, East, and Jong
    Object.keys(balances).forEach(key => {
      if (key !== "Ops") {
        // Ensure we don't overwrite Account A if it exists
        setBalances(prevBalances => ({
          ...prevBalances,
          [key]: {
            ...prevBalances[key],
            balance: remainingBalance
              .mul(prevBalances[key].split)
              .toDP(2, Decimal.ROUND_DOWN),
          },
        }))
      }
    })
  }

  // Effect to recalculate when checkbox or balance changes
  useEffect(() => {
    if (commWalletBalance) {
      calculateBalances()
      console.log("balances", balances)
    }
  }, [includeOpsFund, commWalletBalance])

  useEffect(() => {
    const total = Object.values(balances).reduce(
      (acc, { balance }) => acc.add(balance),
      new Decimal(0)
    )
    setTotalBalance(total)
  }, [balances])

  const handleCheckboxChange = event => {
    console.log("included checkbox got clicked", event.target.checked)
    setIncludeOpsFund(event.target.checked)
  }

  const handleAdminWithdrawClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/generateAdminWithdraw`,

        { balances },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )

      // Handle success response
      console.log("Fund withdraw successfully:", response.data)
    } catch (error) {
      console.error(
        "Failed to withdraw fund:",
        error.response ? error.response.data : error
      )
    }
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Finance" breadcrumbItem="Revenue Split" />
        <Row>
          Commission Wallet Balance: {commWalletBalance?.toString()} USDT
          <Form>
            <FormGroup>
              <Input
                name="checkbox1"
                type="checkbox"
                // checked={includeOpsFund}
                onChange={e => handleCheckboxChange(e)}
              />{" "}
              <Label check>Split 20% to Operational Fund?</Label>
            </FormGroup>
          </Form>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <CardTitle>Split Draft</CardTitle>

                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th>from userIndex</th>
                        <th>split %</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Amount (USDT)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(balances).map(
                        ([name, { uid, balance, address, split }]) => (
                          <tr key={uid}>
                            <td>{uid}</td>
                            <td>{(split * 100).toFixed(2)}%</td>
                            <td>{name}</td>
                            <td>{address}</td>
                            <td>${balance.toFixed(2)}</td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td colSpan="4" style={{ textAlign: "right" }}>
                          Total:
                        </td>
                        <td>${totalBalance.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    // disabled={row.original.status == "completed" ? true : false}
                    onClick={handleAdminWithdrawClick}
                  >
                    {/* {isLoading ? "Loading..." : "Withdraw"} */}
                    Withdraw
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RevenueSplitPage
