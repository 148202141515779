// src/components/filter.
import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Alchemy, Network } from "alchemy-sdk"

import { Table, Row, Col, Button, Input, Progress } from "reactstrap"

import axios from "axios"
import "./Withdraws.scss"

function DatatableTables() {
  const [withdraws, setWithdraws] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [balances, setBalances] = useState({})

  // const [idToken, setIdToken] = useState("")

  const idToken = localStorage.getItem("idToken")
  const authUser = localStorage.getItem("authUser")
  const uid = JSON.parse(authUser).uid

  const adminUids = [
    "VBbZlNV4qrS809MwqK814Y0q0c82", // Jong Ma
    "azob5p0u52YgOXPJjypPa4vJHpx1", // Tom
    "Wp1m7VpfRiPJSRmc7NDmXxSNWvI3", // East
  ]

  //meta title
  document.title = "All Withdraws | Metaverse Casino"

  const getAllWithdraws = async () => {
    console.log("calling getAllWithdraws()...")
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST_URL}/admin/getAllWithdraws`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }) // Adjust the domain as necessary
      .then(response => {
        setWithdraws(response.data)
        console.log("withdraws data", response.data)

        setIsLoading(false)
      })
      .catch(error => {
        console.error("There was an error:", error)
        setError(error.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getAllWithdraws()
  }, []) // Empty dependency array means this effect runs once on mount

  const columns = useMemo(
    () => [
      {
        Header: "timestamp",
        accessor: "timestamp",
      },
      {
        Header: "Withdraw To",
        accessor: "withdrawToAddress",
      },
      {
        Header: "Amount (USDT)",
        accessor: "amount.$numberDecimal",
      },

      {
        Header: "DB Balance (USDT)",
        accessor: "usdTokenBalance.$numberDecimal",
      },
      {
        Header: "uid",
        accessor: "uid",
      },
      {
        Header: "name",
        accessor: "name",
      },

      {
        Header: "userIndex",
        accessor: "userIndex",
      },
      {
        Header: "Withdraw Status",
        accessor: "status",
      },
      {
        Header: "Approvals / Withdraw",
        // accessor: "smartAccountAddress",
        Cell: ({ row }) => {
          const [isLoading, setIsLoading] = useState(false)
          const [approvedCount, setApprovedCount] = useState(0)

          const { _id, status } = row.original
          let approvals = row.original.approvals || {}

          useEffect(() => {
            const approvedAdmins = Object.keys(approvals).filter(
              key => adminUids.includes(key) && approvals[key] === true
            )
            // console.log("approvedAdmins", approvedAdmins)
            setApprovedCount(approvedAdmins.length)
          }, [row])

          // console.log("row.original", row.original)

          const handleWithdrawClick = async () => {
            // const { _id } = row.original
            try {
              setIsLoading(true)

              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/withdrawFund`,

                { _id },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )

              // Handle success response
              console.log("Fund withdraw successfully:", response.data)
              getAllWithdraws()
              setIsLoading(false)
            } catch (error) {
              // Handle error response
              console.error(
                "Failed to withdraw fund:",
                error.response ? error.response.data : error
              )
              setIsLoading(false)
            }
          }

          const handleApproveClick = async () => {
            try {
              setIsLoading(true)

              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/approveWithdraw`,

                { _id },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )

              // Handle success response
              console.log("Approve withdraw successfully:", response.data)
              getAllWithdraws()
              setIsLoading(false)
            } catch (error) {
              // Handle error response
              console.error(
                "Failed to approve withdraw:",
                error.response ? error.response.data : error
              )
              setIsLoading(false)
            }
          }

          const handleCancelApprovalClick = async () => {
            try {
              setIsLoading(true)

              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/cancelWithdrawApproval`,

                { _id },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )

              // Handle success response
              console.log(
                "Cancel withdraw approval successfully:",
                response.data
              )
              getAllWithdraws()
              setIsLoading(false)
            } catch (error) {
              // Handle error response
              console.error(
                "Failed to cancel withdraw approval:",
                error.response ? error.response.data : error
              )
              setIsLoading(false)
            }
          }

          const allApproved = () => {
            const approvedAdmins = Object.keys(approvals).filter(
              key => adminUids.includes(key) && approvals[key] === true
            )
            // console.log("approvedAdmins", approvedAdmins)
            if (approvedAdmins.length === 3) {
              return true
            } else {
              return false
            }
          }

          const userHasApproved = () => {
            // console.log("[userHasApproved] uid", uid)
            // console.log("[userHasApproved] approvals", approvals)

            const userHasApproved = Object.keys(approvals).filter(
              key => key === uid && approvals[key] === true
            )
            if (userHasApproved.length === 1) {
              return true
            } else {
              return false
            }
          }

          if (allApproved()) {
            // show withdraw fund button
            return (
              <>
                <Button
                  color="primary"
                  className="btn btn-primary"
                  disabled={row.original.status == "completed" ? true : false}
                  onClick={handleWithdrawClick}
                >
                  {isLoading ? "Loading..." : "Withdraw"}
                </Button>
                <span className="approvedCount">{`${approvedCount}/3`}</span>
              </>
            )
          } else {
            // check if current user has approved
            // if yes, show cancel button + pending others
            // if no, show approve button + pending others
            if (userHasApproved()) {
              return (
                <>
                  <Button
                    color="danger"
                    className="btn btn-danger"
                    onClick={handleCancelApprovalClick}
                  >
                    {isLoading ? "Loading..." : "Cancel"}
                  </Button>
                  <span className="approvedCount">{`${approvedCount}/3`}</span>
                </>
              )
            } else {
              return (
                <>
                  <Button
                    color="secondary"
                    className="btn btn-secondary"
                    onClick={handleApproveClick}
                  >
                    {isLoading ? "Loading..." : "Approve"}
                  </Button>
                  <span className="approvedCount">{`${approvedCount}/3`}</span>
                </>
              )
            }
          }
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Finance" breadcrumbItem="All Withdraws" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          columns={columns}
          data={withdraws}
          isGlobalFilter={true}
          isAddOptions={false}
          canRefresh={true}
          refreshFunc={getAllWithdraws}
          customPageSize={50}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
