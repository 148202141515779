// src/components/filter.
import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { Alchemy, Network } from "alchemy-sdk"

import { Table, Row, Col, Button, Input } from "reactstrap"

import axios from "axios"

function DatatableTables() {
  const [users, setUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [balances, setBalances] = useState({})

  const idToken = localStorage.getItem("idToken")

  //meta title
  document.title = "All Wallets | Metaverse Casino"

  // alchemy config
  const config = {
    apiKey: "EafEOonmpPFzFYHb2x7wBsP4JOCe4CBB",
    network: Network.MATIC_MAINNET,
  }
  const alchemy = new Alchemy(config)

  //The below token contract address corresponds to USDT
  const usdTokenContractAddresses = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"

  function hexToDecimal(hexString, decimals) {
    return (parseInt(hexString, 16) / Math.pow(10, decimals)).toFixed(decimals)
    // const bigint = BigInt(hexString)
    // const divisor = BigInt(10 ** decimals)
    // console.log("[hexToDecimal] bigint", bigint)
    // console.log("[hexToDecimal] divisor", divisor)
    // return (bigint / divisor).toString()
  }

  function chunkArray(array, chunkSize) {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }
    return chunks
  }

  // Function to find and format the token balance
  function formatTokenBalance(tokenBalances, contractAddress, decimals) {
    const tokenData = tokenBalances.find(
      t => t.contractAddress.toLowerCase() === contractAddress.toLowerCase()
    )
    return tokenData ? hexToDecimal(tokenData.tokenBalance, decimals) : "0"
  }

  async function fetchTokenBalance(address, tokenAddress) {
    try {
      const response = await alchemy.core.getTokenBalances(address, [
        tokenAddress,
      ])

      // console.log("[fetchTokenBalance] response:", response)
      // Assuming USDT has 6 decimal places
      const balance = formatTokenBalance(
        response.tokenBalances,
        tokenAddress,
        6
      )

      // console.log("[fetchTokenBalance] balance after format:", balance)
      return balance
    } catch (error) {
      throw error
    }
  }

  const getAllUsers = async () => {
    console.log("calling getAllUsers()...")
    axios
      .get(`${process.env.REACT_APP_BACKEND_HOST_URL}/admin/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }) // Adjust the domain as necessary
      .then(response => {
        setUsers(response.data)
        console.log(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error("There was an error:", error)
        setError(error.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getAllUsers()
  }, []) // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    async function fetchBalancesInBatches(
      addresses,
      tokenAddress,
      chunkSize,
      delay
    ) {
      const addressChunks = chunkArray(addresses, chunkSize)
      let allBalances = {}

      for (const chunk of addressChunks) {
        // Fetch balances for the current chunk
        const balances = await Promise.all(
          chunk.map(address => fetchTokenBalance(address, tokenAddress))
        )
        // Combine the balances with the main balance object
        chunk.forEach((address, index) => {
          allBalances[address] = balances[index]
        })

        // Wait for the specified delay before processing the next chunk (if not the last chunk)
        if (addressChunks.indexOf(chunk) !== addressChunks.length - 1) {
          await new Promise(resolve => setTimeout(resolve, delay))
        }
      }

      console.log("[All Wallets] results", allBalances)

      return allBalances
    }

    if (users.length > 0) {
      console.log("users > 0", users.length)
      // fetchBalances()

      let addresses = users.map(user => user.smartAccountAddress)

      // console.log("list of smart addresses:", addresses)

      fetchBalancesInBatches(addresses, usdTokenContractAddresses, 15, 1000)
        .then(onchainBalances => {
          const updatedUsers = users.map(user => ({
            ...user,
            onchainBalance: onchainBalances[user.smartAccountAddress] || "0", // Add usdBalance property to each user
          }))
          if (!users[0].onchainBalance) {
            setUsers(updatedUsers)
          }
        }) // Update your state or handle the balances as needed
        .catch(console.error) // Handle any errors
    }
  }, [users]) // Re-fetch when the users array changes

  const columns = useMemo(
    () => [
      {
        Header: "userIndex",
        accessor: "userIndex",
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
      },
      {
        Header: "uid",
        accessor: "uid",
      },
      {
        Header: "Wallet Addr",
        accessor: "smartAccountAddress",
      },
      {
        Header: "On-chain Balance (USDT)",
        accessor: user => {
          let localeNumString = Number(
            parseFloat(user.onchainBalance).toFixed(2)
          ).toLocaleString("en", {
            minimumFractionDigits: 2,
          })

          return localeNumString
        },
        Filter: ({
          column: { filterValue, setFilter, preFilteredRows, id },
        }) => {
          const [checked, setChecked] = useState(false)
          const [value, setValue] = useState(filterValue)

          // Update the filter state when the checkbox is toggled
          const onChange = e => {
            console.log("calling onChange in Filter,", checked)

            setFilter(!checked) // Set filter for non-zero values or remove filter
            setChecked(!checked)
          }

          return (
            <div className="select-filter">
              <label>
                <input type="checkbox" value={checked} onChange={onChange} />
                Show only non-zero
              </label>
            </div>
          )
        },
        filter: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return filterValue ? rowValue != 0 : true // If filter is on, only return non-zero values
          })
        },
      },
      {
        Header: "DB balance",
        accessor: "usdTokenBalance.$numberDecimal",
      },

      {
        Header: "UserType",
        accessor: "userType",
      },
      {
        Header: "Actions",
        // accessor: "smartAccountAddress",
        Cell: ({ row }) => {
          const [isLoading, setIsLoading] = useState(false)

          const handleButtonClick = async () => {
            const { userIndex, onchainBalance } = row.original
            try {
              setIsLoading(true)

              const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_HOST_URL}/admin/sweepFund`,
                {
                  senderUserIndex: userIndex,
                  onchainBalance,
                },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )

              // Handle success response
              console.log(
                "Fund swept successfully for:",
                userIndex,
                response.data
              )
              setIsLoading(false)
            } catch (error) {
              // Handle error response
              console.error(
                "Failed to sweep fund for:",
                userIndex,
                error.response ? error.response.data : error
              )
              setIsLoading(false)
            }
          }

          return (
            <Button
              color="primary"
              className="btn btn-primary"
              disabled={row.original.onchainBalance == 0 ? true : false}
              onClick={handleButtonClick}
            >
              {isLoading ? "Loading..." : "Sweep Fund"}
            </Button>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Finance" breadcrumbItem="All Wallets" />
        {/* <Table columns={columns} data={data} /> */}
        <TableContainer
          columns={columns}
          data={users}
          isGlobalFilter={true}
          canRefresh={true}
          refreshFunc={getAllUsers}
          isAddOptions={false}
          customPageSize={50}
          className="custom-header-css"
        />
      </div>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
