import React from "react"
// import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import { getFirebaseBackend } from "../../helpers/firebase_helper"
import { db } from "firebase-config"
import { getDatabase, set, get, update, ref } from "firebase/database"
import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore"

// import { closedHand_case, nextTile_case1 } from "./case1"
// import { closedHand_case, nextTile_case2 } from "./case2"
// import { closedHand_case, nextTile_case3 } from "./case3"
// import { closedHand_case, nextTile_case4 } from "./case4"
import { closedHand_case } from "./case5"
import { closedHand_case as closedHand2 } from "./case6"
// import { closedHand_case } from "./case7"
// import { closedHand_case } from "./case8"

// const roomRef = "SELF_DRAWN-0_25-0_5/TzgWxLP-CX"
const gameMode = "BASIC"
const priceMode = "0_5-1"
// const priceMode = "1_5-3"
const roomId = "LGmh5B3OFz2J7wZvUh4v"
const gamePriceMode = `${gameMode}-${priceMode}`

const player1 = "lrYV9AaVjScNogQ7awVLPCklQzt1"
const player2 = "na2Yj6EWtjbr2zTHEgQ0cEhb0o03"

async function updatePlayerClosedHand(gamePriceMode, playerId, tiles) {
  // update(ref(getDatabase(), `${roomRef}/playerParams/${playerId}`), {
  //   closedHand: tiles,
  // })

  await updateDoc(doc(getFirestore(), gamePriceMode, roomId), {
    [`playerParams.${playerId}.closedHand`]: tiles,
  })

  return
}

async function updateGameWall(gamePriceMode, roomId) {
  let currentRoomInfo = await getDoc(doc(getFirestore(), gamePriceMode, roomId))

  let currentWall = currentRoomInfo.data().gameParams.wall
  let slicedWall = currentWall.slice(0, 2)

  await updateDoc(doc(getFirestore(), gamePriceMode, roomId), {
    [`gameParams.wall`]: slicedWall,
  })
}

// function updateGameWall(roomRef, tiles) {
//   update(ref(getDatabase(), `${roomRef}/gameParams/wall/`), {
//     closedHand: tiles,
//   })
// }

// function updatePlayerOpenHand(roomRef, playerId, tiles) {
//   update(ref(getDatabase(), `${roomRef}/playerParams/${playerId}`), {
//     openHand: tiles,
//   })
// }

const getPlayerParams = async (roomRef, playerId) => {
  let playerParams = await get(
    ref(getDatabase(), `${roomRef}/playerParams/${playerId}`)
  )

  console.log("playerParams", playerParams)

  return playerParams.val()
}

// async function getInjectedHand(tiles) {
//   let playerClosedHand = (await getPlayerParams(roomRef, previousPlayerId))
//     .closedHand

//   console.log("playerClosedHand", playerClosedHand)

//   let newHand = [...tiles, ...playerClosedHand.slice(1)]

//   // console.log("newHand", newHand)

//   return newHand
// }

async function injectTestCase() {
  console.log("injecting test case...")

  updatePlayerClosedHand(gamePriceMode, player1, closedHand_case)
  updatePlayerClosedHand(gamePriceMode, player2, closedHand2)
  // updateGameWall(gamePriceMode, roomId)

  // updatePlayerClosedHand(roomRef, player2, closedHand_case6)

  // // purge rooms with less than 4 users
  // let allRooms = await get(ref(getDatabase(), `${roomRef}`))
  // let allRoomsVal = allRooms.val()

  // console.log("allRoomsVal", allRoomsVal)

  console.log("done injecting test case...")
}

const newPage = () => {
  return (
    <>
      <div className="page-content">
        {/* <MetaTags>
          <title>New Page | Skote - React Admin & Dashboard Template</title>
        </MetaTags> */}
        <Container fluid={true}>
          <Breadcrumbs title="New Page" breadcrumbItem="New Page" />

          <div className="">Testing Page</div>

          <button onClick={injectTestCase}>Run Test</button>
        </Container>
      </div>
    </>
  )
}

export default newPage
