//
// Case 6.
// Waiting for 3tiao or 8wan to Hu

export const closedHand_case = [
  // 2wan x 3
  {
    defaultParams: {
      suit: "wan",
    },
    id: 301,
    justDrawn: false,
    name: "2万",
    params: {
      suit: "wan",
    },
    value: 2,
    visualCardIndex: 1,
  },
  {
    defaultParams: {
      suit: "wan",
    },
    id: 302,
    justDrawn: false,
    name: "2万",
    params: {
      suit: "wan",
    },
    value: 2,
    visualCardIndex: 1,
  },
  {
    defaultParams: {
      suit: "wan",
    },
    id: 303,
    justDrawn: false,
    name: "2万",
    params: {
      suit: "wan",
    },
    value: 2,
    visualCardIndex: 1,
  },
  // wan 1,2,3
  {
    defaultParams: {
      suit: "wan",
    },
    id: 304,
    justDrawn: false,
    name: "3wan",
    params: {
      suit: "wan",
    },
    value: 3,
    visualCardIndex: 2,
  },
  {
    defaultParams: {
      suit: "wan",
    },
    id: 305,
    justDrawn: false,
    name: "2wan",
    params: {
      suit: "wan",
    },
    value: 2,
    visualCardIndex: 1,
  },
  {
    defaultParams: {
      suit: "wan",
    },
    id: 306,
    justDrawn: false,
    name: "1wan",
    params: {
      suit: "wan",
    },
    value: 1,
    visualCardIndex: 0,
  },
  // 8wan x 2
  {
    defaultParams: {
      suit: "wan",
    },
    id: 307,
    justDrawn: false,
    name: "8wan",
    params: {
      suit: "wan",
    },
    value: 8,
    visualCardIndex: 7,
  },
  {
    defaultParams: {
      suit: "wan",
    },
    id: 308,
    justDrawn: false,
    name: "8wan",
    params: {
      suit: "wan",
    },
    value: 8,
    visualCardIndex: 7,
  },
  // tong 2,3,4
  {
    defaultParams: {
      suit: "tong",
    },
    id: 309,
    justDrawn: false,
    name: "4筒",
    params: {
      suit: "tong",
    },
    value: 4,
    visualCardIndex: 21,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 310,
    justDrawn: false,
    name: "3筒",
    params: {
      suit: "tong",
    },
    value: 3,
    visualCardIndex: 20,
  },
  {
    defaultParams: {
      suit: "tong",
    },
    id: 311,
    justDrawn: false,
    name: "2筒",
    params: {
      suit: "tong",
    },
    value: 2,
    visualCardIndex: 19,
  },
  //3 taio x 2
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 312,
    justDrawn: false,
    name: "3tiao",
    params: {
      suit: "tiao",
    },
    value: 3,
    visualCardIndex: 11,
  },
  {
    defaultParams: {
      suit: "tiao",
    },
    id: 313,
    justDrawn: false,
    name: "3tiao",
    params: {
      suit: "tiao",
    },
    value: 3,
    visualCardIndex: 11,
  },
]
