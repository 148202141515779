import React from "react"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { db } from "firebase-config"
import { getDatabase, set, get, update, ref } from "firebase/database"

const newPage = () => {
  return (
    <>
      <div className="page-content">
        {/* <MetaTags>
          <title>New Page | Skote - React Admin & Dashboard Template</title>
        </MetaTags> */}
        <Container fluid={true}>
          <Breadcrumbs title="New Page" breadcrumbItem="Tiles check" />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Settings</CardTitle>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">
                            Mode String
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="e.g. BASIC-4-8"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Room ID
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="e.g. If4ucYhSy4"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Fetch
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default newPage
